*,
*::before,
*::after {
    box-sizing: border-box;
}

:focus {
    outline: 0;
}

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 1.25;
    background-image: linear-gradient(to bottom, #fecb7c, #e4a16a);
    background-attachment: fixed;
    color: rgba(0, 0, 0, 0.88);
}

svg {
    fill: currentColor;
}

button {
    display: flex;
    padding: 0;
    font: inherit;
    line-height: inherit;
    color: inherit;
    background: none;
    border: 0;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

input,
textarea {
    display: block;
    padding: 8px;
    font: inherit;
    color: inherit;
    background-color: rgb(0, 0, 0, 0.08);
    border: 0;
    width: 100%;
    resize: none;
}

input:hover,
input:focus,
textarea:hover,
textarea:focus {
    background-color: rgb(0, 0, 0, 0.12);
}

input::placeholder,
textarea::placeholder {
    color: rgb(27, 32, 35, 0.5);
}

label {
    display: inline-block;
    font-weight: 600;
    margin-bottom: 4px;
}

h1, h2, h3, h4, h5 {
    margin: 0;
    font-weight: normal;
    font-family: 'Oleo Script', sans-serif;
    color: #2a2222;
}

p {
    margin: 0;
}

p + p {
    margin-block-start: 1.5em;
}

a {
    color: #38a3f1;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

#app {
    position: relative;
    z-index: 1;
    display: flex;
    height: 100%;
}

#portal {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
}

@media (max-width: 1100px) {
    #app {
        flex-direction: column;
    }
}

@media print {
    #app {
        display: block;
    }
}
